import { storyData } from '../building-future/climate-change';
import {
  BoardCommonControllerService,
  BoardVo,
} from '@/__generated__/CommonApi';
import CardImg1 from '@/assets/img/nodiscrimination/wash_card_img1@2x.jpg';
import CardImg2 from '@/assets/img/nodiscrimination/wash_card_img2@2x.jpg';
import CardImg3 from '@/assets/img/nodiscrimination/wash_card_img3@2x.jpg';
import WedoIco1 from '@/assets/img/nodiscrimination/wash_obj1_1@2x.png';
import WedoIco2 from '@/assets/img/nodiscrimination/wash_obj1_2@2x.png';
import WedoIco34 from '@/assets/img/nodiscrimination/wash_obj1_6@2x.png';
import WedoIco31 from '@/assets/img/nodiscrimination/wash_obj2020_1@2x.png';
import WedoIco32 from '@/assets/img/nodiscrimination/wash_obj2020_2@2x.png';
import WedoIco3 from '@/assets/img/nodiscrimination/wash_obj2_1@2x.png';
import WedoIco4 from '@/assets/img/nodiscrimination/wash_obj2_2@2x.png';
import WedoIco33 from '@/assets/img/nodiscrimination/wash_obj2_4@2x.png';
import WashTargetObj from '@/assets/img/nodiscrimination/wash_sec2_obj2@2x.png';
import WashTargetObjM from '@/assets/img/nodiscrimination/wash_sec2_obj2M@2x.png';
import AccordionComponent, { AccordionItem } from '@/components//Accordion';
import { CampaignData } from '@/components/Card/CampaignCard';
import RelativeBoardCard from '@/components/Card/RelativeBoardCard';
import Container from '@/components/Container';
import Image from '@/components/Image';
import LinkSafe from '@/components/LinkSafe';
import {
  SectionCard,
  SectionStroy,
  SectionTarget,
  SectionTop,
  SectionWedo,
} from '@/components/Nodiscrimination';
import PageComponent from '@/components/PageComponent';
import { ProcessList } from '@/components/ProcessList';
import { SectionNews } from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import SwiperBasic from '@/components/Swiper/SwiperBasic';
import ThumbnailFlex from '@/components/ThumbnailFlex';
import { Tit } from '@/components/Titles';
import YoutubeSingle from '@/components/YoutubeSingle';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { PageProps } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';

const card = [
  {
    id: 1,
    title: `커뮤니티 역량 강화`,
    dec: `유니세프는 매년 수억 명을 대상으로 하는 '세계 손씻기의 날(Global Handwashing Day)' 캠페인처럼 지역사회를 기반으로 기본적인 위생 습관에 대해 홍보합니다. 지역사회를 기반으로 한 접근 방식은 공개 배변에 대한 위험성을 널리 알리고 개선하는 데 큰 도움이 됐습니다.`,
    img: CardImg1,
  },
  {
    id: 2,
    title: `학교 지원`,
    dec: `유니세프는 학교 및 의료 시설과 직접 협력하여 기본적인 물과 위생 및 손 씻기 시설에 대한 접근성을 개선하고 감염 예방 및 통제 프로토콜을 수립합니다. 생리대 처리 시설 등 개인이 이용할 수 있는 안전한 위생 시설을 설치하여 학교 보건 위생을 지원합니다. 또한 여자 어린이가 월경 주기에 대해 이해하고 관리할 수 있도록 교육 및 지원 서비스를 제공합니다.`,
    img: CardImg2,
  },
  {
    id: 3,
    title: `지속 가능성 고려`,
    dec: `유니세프는 2021년 말까지 모든 식수와 위생 프로그램(Water, Sanitation and Hygiene; WASH)을 지속 가능하고 기후 변화에 탄력적으로 대응할 수 있도록 개선하는 작업을 추진하고 있습니다. 유니세프는 어린이를 위한 식수와 위생 프로그램 지원 외에도 물 부족 문제를 해결하기 위해 태양열 급수 시스템을 1,000개 이상 설치하거나 보수했습니다.`,
    img: CardImg3,
  },
];

const wedo = [  
  {
    id: 1,
    tit: `2019년 주요 성과`,
    child: [
      {
        id: 1,
        img: WedoIco3,
        desc: [
          {
            dec: `학교 <strong>8,026개교</strong>에 어린이 <strong>240만 명</strong>을 위한 기본 위생 시설 설치`,
          },
        ],
      },
      {
        id: 2,
        img: WedoIco4,
        desc: [
          {
            dec: `<strong>90개국</strong>에서 손 씻기 교육 캠페인 진행`,
          },
        ],
      },
      {
        id: 3,
        img: WedoIco1,
        desc: [
          {
            dec: `<strong>1,830만 명</strong>에게 안전한 식수 제공`,
          },
        ],
      },
    ],
  },
  {
    id: 2,
    tit: `2020년 주요 성과`,
    child: [
      {
        id: 1,
        img: WedoIco31,
        desc: [
          {
            dec: `<strong>1,700만 명</strong>에게 안전한 식수 제공`,
          },
        ],
      },
      {
        id: 2,
        img: WedoIco32,
        desc: [
          {
            dec: `<strong>1,340만 명</strong>에게 <br />기초 위생서비스 제공`,
          },
        ],
      },
    ],
  },
  {
    id: 3,
    tit: `2021년 주요 성과`,
    child: [
      {
        id: 1,
        img: WedoIco3,
        desc: [
          {
            dec: `<strong>106개국</strong>에 손 씻기 프로그램 지원 (목표치 <strong>78개국</strong> 초과 달성)`,
          },
        ],
      },
      {
        id: 2,
        img: WedoIco31,
        desc: [
          {
            dec: `<strong>1,600만 명</strong>에게 안전한 식수 제공`,
          },
        ],
      },
      {
        id: 3,
        img: WedoIco32,
        desc: [
          {
            dec: `<strong>1,990만 명</strong>에게 화장실, 세면대 등 공공 위생 서비스 제공`,
          },
        ],
      },
    ],
  },
  {
    id: 4,
    tit: `2022년 주요 성과`,
    child: [
      {
        id: 1,
        img: WedoIco4,
        desc: [
          {
            dec: `<strong>2,600만 명</strong>에게 화장실, 세면대 등 기본 위생 서비스 제공`,
          },
        ],
      },
      {
        id: 2,
        img: WedoIco31,
        desc: [
          {
            dec: `<strong>3,060만 명</strong>에게 안전한 물 제공`,
          },
        ],
      },
      {
        id: 3,
        img: WedoIco3,
        desc: [
          {
            dec: `<strong>540만 명</strong>에게 기후변화에 강한 수도 시스템 제공`,
          },
        ],
      },
      {
        id: 4,
        img: WedoIco34,
        desc: [
          {
            dec: `태양열로 운영되는 에너지 공급시스템 <strong>1,855개</strong> 설치`,
          },
        ],
      },
      {
        id: 5,
        img: WedoIco33,
        desc: [
          {
            dec: `30개국을 강타한 수인성 질병, 콜레라에 대응`,
          },
        ],
      },
    ],
  },
  {
    id: 5,
    tit: `2023년 주요 성과`,
    child: [
      {
        id: 1,
        img: WedoIco3,
        desc: [
          {
            dec: `학교 <strong>7,500곳</strong> 이상과 의료시설 <strong>3,000곳</strong>에 식수와 위생 서비스 제공`,
          },
        ],
      },
      {
        id: 2,
        img: WedoIco34,
        desc: [
          {
            dec: `<strong>85개국</strong>에 어린이 중심 재난 대비 제도를 갖추도록 지원`,
          },
        ],
      },
      {
        id: 3,
        img: WedoIco33,
        desc: [
          {
            dec: `<strong>8개국</strong>에서 세계 최초로 <br/>사이클론 보험 운영`,
          },
        ],
      },
    ],
  },
];

const NavigationButton = styled.div``;

const Wash: React.FC<PageProps> = ({ location }) => {
  const [slideData, setSlideData] = useState<storyData[]>([]);
  const [thumbs, setThumbs] = useState<CampaignData[]>([]);

  const loadSlideData = useCallback(async () => {
    try {
      const {
        data: childStory,
      } = await BoardCommonControllerService.foListAllUsingGet({
        boardCategoryCode: ['ST04'],
        outDateDispYn: `Y`,
      });
      setSlideData(
        childStory.map((story: BoardVo) => ({
          id: story.boardIndexNumber,
          subject: story.subject,
          contents: story.contents,
          optData1: story.optData1,
          image: story.imgAttGrpNoTn,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  const loadThumbs = useCallback(async () => {
    try {
      const { data } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: ['280', '285', '290'],
        pagePerCount: 8,
        category: ['A003'],
      });
      const articles = data as any;
      setThumbs(
        articles
          .map((article: BoardVo) => ({
            id: article.boardIndexNumber,
            title: article.subject,
            pcImage: article.imgAttGrpNoTn,
            mobileImage: article.imgAttGrpNoTn,
            link: `/what-we-do/news/${article.boardIndexNumber}`,
            date: article.firstRegisterDate,
            boardCategoryCode: article.boardCategoryCode,
          }))
          .sort((a, b) => new Date(b.date) - new Date(a.date) || b.id - a.id),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    loadSlideData();
    loadThumbs();
  }, [loadSlideData, loadThumbs]);

  return (
    <LayoutWithTitle
      location={location}
      title="안전하고 지속 가능한 환경"
      description="for every child, a clean and safe environment"
    >
      <SectionTop className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1-5" color="sky" weight="normal">
                <PageComponent id="title1">
                  모든 어린이는 안전하고{` `}
                  <br className="small-hide" />
                  지속 가능한 기후와 환경에서{` `}
                  <br className="small-hide" />
                  자랄 권리가 있습니다
                </PageComponent>
              </Tit>
            </h2>
            <p className="head-dec">
              <PageComponent id="content1">
                유니세프는 모든 어린이가 안전하고 지속 가능한 환경을 누릴 수
                있도록 식수와 위생시설을 지원합니다. 긴급 상황을 포함하여 농촌과
                도시 지역에서 손 씻기와 같이 기본적인 위생 습관을 장려합니다.
                또한 재난 위험은 줄이고 회복력은 높이는 사업을 펼치고, 평화를
                구축하고 기후 위기 대응에 힘씁니다. 효과적인 지원을 위해
                유니세프는 정부, 민간 부문, 학계, 시민 단체 및 지역사회와 긴밀히
                협력합니다.
              </PageComponent>
            </p>
          </SectionHeader>
        </Container>
        <PageComponent id="youtube1">
          <YoutubeSingle videoId="UwEE4Fh2Hj8" />
        </PageComponent>
      </SectionTop>

      <SectionCard className="by-sub-main-layout">
        <Container>
          <ThumbnailFlex thumbs={card} />
        </Container>
      </SectionCard>

      <SectionTarget className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc sec-header">
            <div className="col-header">
              <h2>
                <Tit size="s1">
                  2022-2025 <br />
                  유니세프 중점 사업 목표
                </Tit>
              </h2>
              <ul className="blit">
                <li>
                  안전한 식수 이용자 <strong>증가</strong>
                </li>
                <li>
                  안전한 위생 시설 이용자 <strong>증가</strong>
                </li>
                <li>
                  비위생적인 물과 위생 시설로 인한 5세 미만 사망률{' '}
                  <strong>감소</strong>
                </li>
              </ul>
            </div>
            <Image pcSrc={WashTargetObj} mobileSrc={WashTargetObjM} />
          </SectionHeader>
        </Container>
      </SectionTarget>

      <SectionWedo className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">
                모든 어린이의 안전하고 지속 가능한 환경을 위해,{' '}
                <br className="m-hide" /> 전 세계 파트너와 함께 유니세프가 한 일
              </Tit>
            </h2>
          </SectionHeader>

          <AccordionComponent initialKey={wedo[wedo.length - 1].id}>
            {wedo.map((row) => (
              <AccordionItem
                key={row.id}
                toggleKey={row.id}
                renderToggle={(onClick) => (
                  <NavigationButton
                    onClick={onClick}
                    className="accordion-opener"
                  >
                    {row.tit}
                  </NavigationButton>
                )}
              >
                <div className="accordion-body">
                  <ProcessList itemData={row.child} />
                </div>
              </AccordionItem>
            ))}
          </AccordionComponent>
        </Container>
      </SectionWedo>

      <SectionStroy className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">어린이 이야기</Tit>
            </h2>
          </SectionHeader>
        </Container>
        <div className="section-content">
          <div className="obj t1" />
          <div className="obj t2" />
          <Container>
            <div className="content-container">
              <SwiperBasic
                css={`
                  padding: 0;
                `}
              >
                <Swiper slidesPerView="auto" navigation>
                  {slideData.map((row) => (
                    <SwiperSlide key={row.id}>
                      <div className="story-flex">
                        <div className="col-img">
                          <Image pcSrc={row.image} mobileSrc={row.image} />
                        </div>
                        <div className="col-dec">
                          <header>
                            <Tit size="s4" color="white">
                              &ldquo;{row.subject}&rdquo;
                            </Tit>
                            {row.optData1 !== null && row.optData1 !== `` && (
                              <p> - {row.optData1} </p>
                            )}
                          </header>
                          <p className="dec">{row.contents}</p>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </SwiperBasic>
            </div>
          </Container>
        </div>
      </SectionStroy>

      <SectionNews className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <div className="flex-middle">
              <h2>
                <Tit size="s1">최근 소식</Tit>
              </h2>
              <LinkSafe to="/what-we-do/news?category=A003" className="more">
                <span className="txt">더 보기</span>
              </LinkSafe>
            </div>
          </SectionHeader>
          {thumbs.length > 0 && (
            <RelativeBoardCard
              newslist={thumbs}
              isDate={false}
              isSwiper
              overflowHidden
            />
          )}
        </Container>
      </SectionNews>
    </LayoutWithTitle>
  );
};

export default Wash;
